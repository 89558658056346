import {
  Navbar, DropdownToggle, DropdownMenu, DropdownItem, Dropdown, Button, Container, Col, Row
} from 'reactstrap';
import { useEffect, useState } from 'react';
import { Link, Params, useNavigate, useParams } from 'react-router-dom';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../features/hooks';
import { getTopicsAsync, selectTopics, selectTopicsStatus } from '../features/slices/topicSlice';
import Search from './Search';
import { selectPosts } from '../features/slices/postSlice';
import { useSelector } from 'react-redux';
import { GetPostResponseModel } from '../ResponseModels/GetPostResponseModel';


const Header = () => {
  const [topicDropdownOpen, setTopicDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const posts = useSelector(selectPosts);
  const { topicLink, postLink } = useParams<Params>();
  const dispatch = useAppDispatch();
  const topics = useAppSelector(selectTopics);
  const topicsStatus = useAppSelector(selectTopicsStatus);
  const [results, setResults] = useState<GetPostResponseModel[]>();
  const [selectedPost, setSelectedPost] = useState<GetPostResponseModel>();

  type changeHandler = React.ChangeEventHandler<HTMLInputElement>;

  const handleChange: changeHandler = (e) => {
    const { target } = e;

    if (!target.value.trim()) {
      return setResults([]);
    }

    const filteredValue = posts.filter((post) =>
      post.title.toLocaleLowerCase()
        .includes(target.value.toLocaleLowerCase()));

    setResults(filteredValue);
  }

  useEffect(() => {
    if (topicsStatus !== 'idle') {
      return;
    }

    dispatch(getTopicsAsync());
  }, [topics, topicsStatus, dispatch]);

  return (
    // <>
    //   {topics && <Navbar
    //     fixed="top"
    //     className="p-0 bg-white border-bottom border-gray flex-md-nowrap ">
    //     <Link
    //       to={'/'}
    //       className="col-sm-2 col-xs-2 col-md-2 col-xl-2 col-lg-2 me-0 px-3 
    //         d-flex align-items-center text-decoration-none bg-white">
    //       <h4 style={{ color: 'black', fontWeight: 'bold', marginTop: '8px' }}>
    //         X Scrolls
    //       </h4>
    //     </Link>

    //     {collapsed
    //       && <div className='nav-item'>
    //         <button
    //           className="navbar-toggler position-absolute d-md-none collapsed"
    //           type="button"
    //           data-bs-toggle="collapse"
    //           data-bs-target="#sidebarMenu"
    //           aria-controls="sidebarMenu"
    //           aria-expanded="false"
    //           aria-label="Toggle navigation"
    //           style={{ marginLeft: '20px' }}
    //         >
    //           <span className="navbar-toggler-icon"></span>
    //         </button>
    //       </div>}

    //     <div className='form-control mx-5'>
    //       <Search
    //         results={results}
    //         onChange={handleChange}
    //         renderItem={(item) => <div className='search-title p-1'
    //           style={{ fontWeight: '600' }}>
    //           {item.title}
    //         </div>}
    //         onSelect={(item) => {
    //           setSelectedPost(item);
    //           navigate(`/${item.topicLink}/${item.link}`);
    //         }}
    //         value={selectedPost?.title} />
    //     </div>

    //     <div className="navbar-nav">
    //       <div className="nav-item text-nowrap">
    //         {topicsStatus === 'succeeded'
    //           && <Dropdown
    //             className='px-5'
    //             isOpen={topicDropdownOpen}
    //             direction='down'
    //             toggle={() => setTopicDropdownOpen((prevState) => !prevState)}
    //             style={{
    //               borderRadius: '15px',
    //               borderStyle: 'solid',
    //               borderWidth: '1px',
    //               borderColor: 'rgb(172, 172, 172)',
    //               display: 'block', width: '100%'
    //             }}
    //           >
    //             <DropdownToggle
    //               className='mx-4 m-0'
    //               style={{
    //                 fontSize: '16px',
    //                 color: 'black',
    //                 backgroundColor: 'white',
    //                 fontWeight: '600',
    //                 display: 'block', width: '100%'
    //               }}
    //               nav
    //               caret>
    //               {topicLink
    //                 ? topics.find(topic => topic.link === topicLink)?.name
    //                 : 'All Topics'}
    //             </DropdownToggle>
    //             <DropdownMenu
    //               className='mt-0 m-0 p-2 bg-white'
    //               style={{}}>
    //               {topics?.map(x => {
    //                 return <DropdownItem
    //                   key={x.id}>
    //                   <Link
    //                     style={{ all: 'unset', width: '100%', display: 'block' }}
    //                     to={`/${x.link}`}>
    //                     {x.name}
    //                   </Link>
    //                 </DropdownItem>
    //               })}
    //             </DropdownMenu>
    //           </Dropdown>}
    //       </div>
    //     </div>
    //     <div className="nav-item text-nowrap px-4" style={{ fontSize: '16px', }}>
    //       <a href="/about" style={{ color: 'black', fontWeight: '600', backgroundColor: 'white' }} className="nav-link">
    //         About
    //       </a>
    //     </div>
    //   </Navbar >}</>

    <Navbar
      className='flex-md-nowrap p-0 bg-white border-bottom border-gray'
      fixed='top'>
      {/* <header className="navbar sticky-top flex-md-nowrap p-0"> */}
      {/* <a className="navbar-brand col-md-3 col-lg-2 me-0 px-3" href="#">X Scrolls</a> */}

      <Link
        to={'/'}
        className=" col-md-3 col-lg-2 me-0 px-3 p-2 bg-white text-decoration-none">
        <h4 style={{ color: 'black', fontWeight: 'bold', marginTop: '8px', borderRight: 'none' }}>
          X Scrolls
        </h4>
      </Link>
      {/* <button className="navbar-toggler position-absolute d-md-none collapsed"
        type="button" data-bs-toggle="collapse"
        data-bs-target="#sidebarMenu" aria-controls="sidebarMenu"
        aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button> */}

      {postLink
        && <div className='nav-item'>
          <button
            className="navbar-toggler position-absolute d-md-none collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#sidebarMenu"
            aria-controls="sidebarMenu"
            aria-expanded="false"
            aria-label="Toggle navigation"
            style={{ marginLeft: '20px' }}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>}

      <div className='form-control mx-5  text-nowrap'>
        <Search
          results={results}
          onChange={handleChange}
          renderItem={(item) => <div className='search-title p-1'
            style={{ fontWeight: '600' }}>
            {item.title}
          </div>}
          onSelect={(item) => {
            setSelectedPost(item);
            navigate(`/${item.topicLink}/${item.link}`);
          }}
          value={selectedPost?.title} />
      </div>

      <div className="navbar-nav">
        <div className="nav-item text-nowrap">
          {/* <Button>Some button</Button> */}
          <div className="nav-item text-nowrap">
            {topicsStatus === 'succeeded'
              && <Dropdown
                className='px-5'
                isOpen={topicDropdownOpen}
                direction='down'
                toggle={() => setTopicDropdownOpen((prevState) => !prevState)}
                style={{
                  borderRadius: '15px',
                  borderStyle: 'solid',
                  borderWidth: '1px',
                  borderColor: 'rgb(172, 172, 172)',
                  display: 'block', width: '100%'
                }}
              >
                <DropdownToggle
                  className='mx-4 m-0'
                  style={{
                    fontSize: '16px',
                    color: 'black',
                    backgroundColor: 'white',
                    fontWeight: '600',
                    display: 'block', width: '100%'
                  }}
                  nav
                  caret>
                  {topicLink
                    ? topics.find(topic => topic.link === topicLink)?.name
                    : 'All Topics'}
                </DropdownToggle>
                <DropdownMenu
                  className='mt-0 m-0 p-2 bg-white'
                  style={{}}>
                  {topics?.map(x => {
                    return <DropdownItem
                      key={x.id}>
                      <Link
                        style={{ all: 'unset', width: '100%', display: 'block' }}
                        to={`/${x.link}`}>
                        {x.name}
                      </Link>
                    </DropdownItem>
                  })}
                </DropdownMenu>
              </Dropdown>}
          </div>
        </div>
      </div>
      <div className="nav-item text-nowrap px-4" style={{ fontSize: '16px', }}>
        <a href="/about" style={{ color: 'black', fontWeight: '600', backgroundColor: 'white' }} className="nav-link">
          About
        </a>
      </div>
      {/* </header> */}
    </Navbar>
  );

}
export default Header;