import { Link, Outlet } from "react-router-dom";
import { host } from "../../configs/config";
import '../../styles/dashboard.css';
import React, { useEffect } from "react";
import { getRoleAsync, selectUser, selectUserStatus } from "../../features/slices/userSlice";
import { useAppDispatch, useAppSelector } from "../../features/hooks";
import { getPostsAsync } from "../../features/slices/postSlice";
import { getTopicsAsync } from "../../features/slices/topicSlice";
import { getCategoriesAsync } from "../../features/slices/categorySlice";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { AccordionHeader, Button } from "reactstrap";
import AccountHeader from "./AccountHeader";

const AccountPanel = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const currentUser = useAppSelector(selectUser);
    const currentUserStatus = useAppSelector(selectUserStatus);
    const { instance, inProgress } = useMsal();

    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        dispatch(getRoleAsync());
        dispatch(getPostsAsync());
        dispatch(getTopicsAsync());
        dispatch(getCategoriesAsync());
    }, [currentUser, dispatch]);
    return (
        <>
            <AccountHeader />

            <div className="container-fluid">
                {currentUser.role === 'admin' &&
                    <div className="row">
                        <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-light sidebar-account collapse">
                            <div className="position-sticky pt-3">
                                <ul className="nav flex-column">
                                    <li className="nav-item">
                                        <Link
                                            className="nav-link active"
                                            aria-current="page"
                                            to={`${host.baseUiUrl}/account/topics`}>
                                            Topics
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link
                                            className="nav-link active"
                                            aria-current="page"
                                            to={`${host.baseUiUrl}/account/categories`}>
                                            Categories
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link
                                            className="nav-link active"
                                            aria-current="page"
                                            to={`${host.baseUiUrl}/account/posts`}>
                                            Posts
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link
                                            className="nav-link active"
                                            aria-current="page"
                                            to={`${host.baseUiUrl}/account/create-post`}>
                                            Create Post
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link
                                            className="nav-link active"
                                            aria-current="page"
                                            to={`${host.baseUiUrl}/account/files`}>
                                            Files
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </nav>

                        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">


                            <Outlet />
                        </main>
                    </div>}
            </div>
        </ >
    )
}

export default AccountPanel;