import axios from "axios";
import { host } from "../configs/config";

export const searchEngineOptimizationApi = {
    getSiteMap() {
        const headers = {
            "Content-Type": "application/xml; charset=utf-8"
        };
        return axios.get(`${host.baseServerUrl}/api/sitemap`, { headers });
    }
}