import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);
  return (
    <main className="my-5 py-7" style={{ padding: '70px' }}>
      <p>This page does not exist</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </main>);
}