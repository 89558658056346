import { useParams } from "react-router-dom";
import DOMPurify from "dompurify";
import '../styles/post.css';
import React, { useEffect } from "react";
import { useAppSelector } from "../features/hooks";
import { selectPostsByPostLink } from "../features/slices/postSlice";
import { Helmet } from "react-helmet";
import Prism from "prismjs";
import '../styles/code/light.css'
import 'prismjs/components/prism-csharp';
import 'prismjs/components/prism-sql';

type Params = {
    postLink: string;
    topicLink: string;
}

const Post = (): JSX.Element => {
    const { postLink } = useParams<Params>();
    const post = useAppSelector(state =>
        selectPostsByPostLink(state, postLink));

    const postText = post?.data ?? '';
    const descriptioin = postText.substring(
        postText.indexOf('<p>') + 3,
        postText.indexOf('</p>'));

    useEffect(() => {
        Prism.highlightAll();
        window.scrollTo(0, 0);
    }, [postLink]);

    return (
        <>
            {post && (
                <div className="position-relative col-md-12 ms-sm-auto col-lg-12 ">
                    <Helmet>
                        <title>{post.title}</title>
                        <meta
                            name="description"
                            content={descriptioin}
                        />
                        <meta
                            property="og:description"
                            content={descriptioin}
                        />
                    </Helmet>
                    <h1 className="d-block pb-1 text-dark">
                        {post?.title}
                    </h1>

                    {post?.data && <article
                        dangerouslySetInnerHTML={
                            {
                                __html: DOMPurify.sanitize(
                                    post?.data)
                            }} />}
                </div>
            )}
        </>
    );
};

export default Post;
