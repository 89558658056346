import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GetPostResponseModel } from '../../ResponseModels/GetPostResponseModel';
import { PostApi } from '../../api/postApi';
import { RootState } from '../store';

export interface PostsState {
    value: GetPostResponseModel[];
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | undefined;
};

const initialState: PostsState = {
    value: [],
    status: 'idle',
    error: undefined,
};

export const getPostsAsync = createAsyncThunk(
    'posts/fetchPosts',
    async () => {
        const response = await PostApi.getPosts();

        return response.data;
    }
);

export const postsSlice = createSlice({
    name: 'posts',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getPostsAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getPostsAsync.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.value = action.payload;
            })
            .addCase(getPostsAsync.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
    }
});

export const selectPosts = (state: RootState) => state.posts.value;

export const selectPostsStatus = (state: RootState) => state.posts.status;

export const selectPostsByPostLink = (state: RootState, postLink) =>
    state.posts.value
        .find(post => post.link === postLink);

export const selectPostById = (state: RootState, postId) =>
    state.posts.value
        .find(post => post.postId === Number(postId));

export default postsSlice.reducer;

