import { Col, Container, Row } from 'reactstrap';
import SideBar from './SideBar';
import { Link, Outlet, Params, useParams } from "react-router-dom";
import Header from './Header';
import React from 'react';
import { useAppSelector } from '../features/hooks';
import { selectCategoriedByTopicLink } from '../features/slices/categorySlice';
import { Helmet } from 'react-helmet';
import { selectTopics } from '../features/slices/topicSlice';

function TopicPage() {
    const { postLink, topicLink } = useParams<Params>();
    const categories = useAppSelector(state => selectCategoriedByTopicLink(state, topicLink));
    const topics = useAppSelector(selectTopics);

    const topic = topics.find(topic => topic.link === topicLink)?.name;

    return <>
        <main>
            {/* <Header collapsed /> */}
            {/* <Container className="px-0 p-5 mt-4 mt-sm-5 mt-md-5 mt-lg-0"> */}
            <Container
             fluid
              className='px-0 p-5 mt-4 mt-sm-5 mt-md-5 mt-lg-0'
              style={{}}>
                <Row
                    className="pt-5 pt-md-5 w-100 px-4 px-xl-0 position-relative"
                >
                    <Col style={{paddingLeft: "25px"}}
                        sm={{ size: 12, order: 1 }}
                        xs={{ size: 0, order: 1 }}
                        md={{ size: 0, order: 1 }}
                        lg={{ size: 0, order: 1 }}
                        xl={{ size: 2, order: 1 }}
                        tag="aside"
                        className="pb-0 mb-5 pb-md-0 mb-md-0 mx-auto mx-md-0"
                    >
                        <SideBar />
                    </Col>

                    <Col
                        sm={{ size: 12, order: 1 }}
                        xs={{ size: 12, order: 2 }}
                        md={{ size: 12, offset: 0 }}
                        lg={{ size: 12, offset: 0 }}
                        xl={{ size: 9, order: 1, offset: 1 }}
                        xxl={{ size: 6, order: 1, offset: 1 }}
                        tag="section"
                        className="py-0 mb-5 py-md-0 mb-md-0"
                    >
                        {!postLink && <div className="position-relative col-md-12 ms-sm-auto col-lg-12 ">
                            <Helmet>
                                <title>{topic}</title>
                            </Helmet>
                            <article>{categories.map(category => {
                                return <>
                                    <h3>{category.categoryName}</h3>
                                    <ul style={{ listStyleType: 'none' }}>
                                        {category.posts.map(post => {
                                            return <li className='py-1'>
                                                <Link style={{ backgroundColor: 'white', }}
                                                    to={post.link}>
                                                    <div style={{ fontSize: '18px' }}>{post.title}</div>
                                                </Link>
                                            </li>
                                        })}
                                    </ul>
                                </>
                            })}</article>
                        </div>}
                        <Outlet />
                    </Col>
                </Row>
            </Container>
        </main>
    </>
}

export default TopicPage;