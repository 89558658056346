import { createBrowserRouter } from "react-router-dom"
import AccountPanel from "./components/account/AccountPanel"
import CategoriesPanel from "./components/account/CategoriesPanel"
import PostsPanel from "./components/account/PostsPanel"
import TopicsPanel from "./components/account/TopicsPanel"
import Main from "./components/Main"
import Post from "./components/Post"
import TestComponent from "./components/TestComponent"
import TopicPage from "./components/TopicPage"
import ErrorPage from "./components/ErrorPage"
import EditPost from "./components/account/EditPost"
import CreatePost from "./components/account/CreatePost"
import React from "react"
import About from "./components/About"
import SiteMap from "./components/SiteMap"
import FilesPanel from "./components/account/FilesPanel"

export const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/:topicLink",
        errorElement: <ErrorPage />,
        element: <TopicPage />,
        children: [
          {
            path: "/:topicLink/:postLink",
            element: <Post />
          },
        ]
      },
    ]
  },
  {
    path: "/sitemap2.xml",
    element: <SiteMap />,
  },
  {
    path: '/account',
    element: <AccountPanel />,
    children: [
      {
        path: 'posts',
        element: <PostsPanel />
      },
      {
        path: 'categories',
        element: <CategoriesPanel />
      },
      {
        path: 'topics',
        element: <TopicsPanel />
      },
      {
        path: 'edit-post/:postId',
        element: <EditPost />
      },
      {
        path: 'create-post',
        element: <CreatePost />
      },
      {
        path: 'files',
        element: <FilesPanel />
      }
    ]
  },
  {
    path: '/about',
    element: <About />
  },
  {
    path: '/test',
    element: <TestComponent />
  },
]);