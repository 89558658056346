import axios from "axios"
import { host } from "../configs/config";

export const PostApi = {

    getPosts() {
        return axios
            .get(`${host.baseServerUrl}/api/posts`);
    },

    addPost(
        data: string,
        title: string,
        postlink: string,
        userId: string,
        categoryId: string) {

        const body = {
            data: data,
            title: title,
            postlink: postlink,
            userId: userId,
            categoryId: categoryId
        };

        const headers = {
            'Accept': 'application/json',
        };

        const result = axios
            .post<any, string>(
                `${host.baseServerUrl}/api/post`,
                body,
                { headers });

        return result;
    },

    updatePost(
        data: string,
        title: string,
        postLink: string,
        userId: string,
        postId: string) {
        const body = {
            data: data,
            title: title,
            postLink: postLink,
            userId: userId,
            postId: postId
        }

        const headers = {
            'Accept': 'application/json',
        };

        const result = axios.put<any, string>(
            `${host.baseServerUrl}/api/post`,
            body,
            { headers });

        return result;
    },

    deletePost(postId: number) {
        return axios.delete(`${host.baseServerUrl}/api/posts/${postId}`);
    },
}