import { Link, Params, useParams } from "react-router-dom";
import { PostResponseModel } from "../ResponseModels/PostResponseModel";
import React from "react";

type PostMenuProps = {
    posts: PostResponseModel[];
    id: string;
    collapseStyle: string
}

function PostMenu({ posts, id, collapseStyle }: PostMenuProps): JSX.Element {
    const { postLink } = useParams<Params>();

    return (
        <div className={collapseStyle}
            id={id}>
            <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                {posts.map((post) => {
                    const link = `${post.link}`;
                    const isActive = postLink === post.link;
                    const fontWeight = isActive ? '400' : 'initial';
                    const bacgroundColor = isActive ? 'rgba(210, 210, 210, 0.377)' : ''
                    const color = isActive ? 'rgb(8, 65, 163)' : 'black'

                    return <Link
                    style={{fontSize: '15px', marginLeft: '15px', backgroundColor: bacgroundColor, fontWeight: fontWeight}}
                        key={post.title}
                        className=" d-inline-flex text-decoration-none rounded "
                        to={link}>{post.title}</Link>
                })}
            </ul>
        </div>)
}

export default PostMenu;