import React, { useEffect, useState } from "react";
import { FileApi } from "../../api/fileApi";
import { Button, Card, CardBody, CardColumns, CardGroup, CardSubtitle, CardText, CardTitle, Col, Row } from "reactstrap";

const FilesPanel = () => {
    const [files, setFiles] = useState([]);

    useEffect(() => {
        FileApi.getFiles().then(result => {
            setFiles(result.data);
            console.log(result.data);
        });
    }, [])

    const result = files.map(file => {

        return <Card
            style={{
                width: '18rem'
            }}
        >
            <img
                width={250}
                alt="Sample"
                src={file.uri}
            />
            <CardBody>
                <CardSubtitle
                    className="mb-2 text-muted"
                    tag="h6"
                >
                    {file.name}
                </CardSubtitle>
                <Button>
                    Button
                </Button>
            </CardBody>
        </Card>
    })
    return <Row> {result}</Row>;
}

export default FilesPanel;