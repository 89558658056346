import React, { useEffect, useState } from "react"


const ChildComponent1 = ({ ...props }: any) => {
    const { message } = props[0];

    return <h1>{message}</h1>
}

const ChildComponent2 = () => {
    const [list, setList] = useState<number[]>([]);

    useEffect(() => {
        // could be data from API request
        setList([1, 2, 3, 4, 5]);
    }, []);

    return <div>
        <ul>
            {list?.map(item => {
                return <li key={item}>
                    {item}
                </li>
            })}
        </ul>
    </div>
}

const createHOC = WrappedComponent =>
    (...props): JSX.Element => {
        console.log(props);

        return <WrappedComponent {...props} />
    };

const App = () => {
    const HOC1 = createHOC(ChildComponent1);
    const HOC2 = createHOC(ChildComponent2);

    return <>
        <HOC1 message={'Hello World!'} />
        <HOC2 />
    </>
}

export default App;