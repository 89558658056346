
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import TopicResponseModel from '../../ResponseModels/TopicResponseModel';
import { TopicApi } from '../../api/topicApi';

export interface TopicsState {
    value: TopicResponseModel[];
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | undefined;
}

const initialState: TopicsState = {
    value: [],
    status: 'idle',
    error: undefined,
}

export const getTopicsAsync = createAsyncThunk(
    'topics/fetchTopics',
    async () => {
        const response = await TopicApi.getTopics();

        return response.data;
    }
);

export const topicsSlice = createSlice({
    name: 'posts',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getTopicsAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getTopicsAsync.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.value = action.payload;
            })
            .addCase(getTopicsAsync.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
    }
});

export const selectTopics = (state: RootState) => state.topics.value;

export const selectTopicsStatus = (state: RootState) => state.topics.status;

export default topicsSlice.reducer;

