import {
    Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle,
    Form, Input, Modal, ModalBody, ModalFooter, ModalHeader, Table
} from "reactstrap";
import { FormEvent, useState } from "react";
import { CategoryApi } from "../../api/categoryApi";
import { TopicModel } from "../../Models/interfaces";
import { useAppSelector } from "../../features/hooks";
import { selectCategories } from "../../features/slices/categorySlice";
import React from "react";
import { selectTopics, selectTopicsStatus } from "../../features/slices/topicSlice";
import { selectCategoriedByTopicLink } from "../../features/slices/categorySlice";

const CategoryPanel = (): JSX.Element => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [topic, setTopicName] = useState<TopicModel | undefined>(undefined);
    const [modal, setModal] = useState(false);
    const [modalProps, setModalProps] = useState<{ categoryId?: string, categoryName?: string }>({});
    const [deleteModal, setDeleteMoadal] = useState(false);
    const [categoryToDelete, setCategoryToDelete] = useState<{ id: string, title: string } | null>(null);

    const tooggleDeleteModal = () => setDeleteMoadal(!deleteModal);

    const deleteCategory = (id: string) => {
        CategoryApi.deleteCategory(id).catch(error => console.log(error));
    }

    const categories = useAppSelector(state => topic !== undefined
        ? selectCategoriedByTopicLink(state, topic.link)
        : selectCategories(state));

    const topics = useAppSelector(selectTopics);
    const topicsStatus = useAppSelector(selectTopicsStatus);

    const handleAddCategory = async (event: FormEvent) => {
        event.preventDefault();
        const { categoryname } = document.forms[0];

        if (!topic) {
            return;
        }

        CategoryApi.addCategory(categoryname.value, topic?.id);
    };

    const toogle = () => {
        setDropdownOpen((prevState) => !prevState);
    }

    const renderCreateCategoryForm = (
        <div className="form">
            <form onSubmit={handleAddCategory}>
                <div className="input-container">
                    <label>Add Category Name </label>
                    <input type="text" name="categoryname" required />
                </div>
                <div className="button-container">
                    <input type="submit" />
                </div>
            </form>
        </div>
    );

    const toggleModal = () => {
        setModal(!modal);
    }

    const openModal = (
        categoryId: string,
        categoryName: string) => {

        setModalProps({ categoryId: categoryId, categoryName: categoryName });
        setModal(!modal);
    }

    const handleUpdateCategory = async () => {
        if (!modalProps.categoryId || !modalProps.categoryName) {
            return;
        }

        const { categoryname } = document.forms[1];

        console.log(categoryname.value);
        console.log(categoryname.data);

        await CategoryApi.updateCategory(modalProps.categoryId, categoryname.value);

        setModal(!modal);
    }

    return <>
            <Modal
                fade
                isOpen={deleteModal}
                toggle={tooggleDeleteModal}>
                <ModalHeader toggle={tooggleDeleteModal}>Delete Category</ModalHeader>
                <ModalBody>
                    Are you shure you want to delete {categoryToDelete?.title} category?
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={tooggleDeleteModal}>
                        Cancel
                    </Button>{' '}
                    <Button color="secondary" onClick={() => {
                        tooggleDeleteModal();
                        categoryToDelete && deleteCategory(categoryToDelete?.id);
                    }}>
                        Delete anyway
                    </Button>
                </ModalFooter>
            </Modal>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h1 className="h2">Categories</h1>
            <div className="btn-toolbar mb-2 mb-md-0">
                <div className="me-2">
                    <button type="button" className="btn btn-sm btn-outline-secondary dropdown-toggle">
                        <span data-feather="calendar"></span>
                        Topics
                    </button>
                </div>
                <div className="me-2">
                    <button type="button" className="btn btn-sm btn-outline-secondary dropdown-toggle">
                        <span data-feather="calendar"></span>
                        Categories
                    </button>
                </div>
                <div className="btn-group me-2">
                    <button type="button" className="btn btn-sm btn-outline-secondary">Share</button>
                    <button type="button" className="btn btn-sm btn-outline-secondary">Export</button>
                </div>
                <button type="button" className="btn btn-sm btn-outline-secondary dropdown-toggle">
                    <span data-feather="calendar"></span>
                    This week
                </button>
            </div>
        </div>
        <div>
            <Modal isOpen={modal} toggle={toggleModal} unmountOnClose={true}>
                <Form onSubmit={handleUpdateCategory}>
                    <ModalHeader toggle={toggleModal}>Edit Category</ModalHeader>
                    <ModalBody>
                        <Input
                            type="text"
                            name="categoryname"
                            defaultValue={modalProps.categoryName}
                            rows={1}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            type="submit">
                            Save
                        </Button>{' '}
                    </ModalFooter>
                </Form>
            </Modal>
        </div>
        <Dropdown
            isOpen={dropdownOpen}
            direction='down'
            toggle={toogle}
            style={{ position: 'absolute' }}>
            <DropdownToggle caret>{topic ? topic.name : 'Topics'}</DropdownToggle>
            <DropdownMenu>
                {topics?.map(topic => {
                    return <DropdownItem
                        onClick={(event) => {
                            setTopicName({
                                id: topic.id,
                                name: topic.name,
                                link: topic.link
                            })
                        }}>
                        {topic.name}
                    </DropdownItem>
                })}
            </DropdownMenu>
        </Dropdown>
        {topic &&
            <Table style={{ marginTop: '70px' }} >
                <thead>
                    <tr>
                        <th>
                            Id
                        </th>
                        <th>
                            Category Name
                        </th>
                        <th>
                            TopicId
                        </th>
                        <th>
                            Topic Name
                        </th>
                        <th>
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {categories && categories?.map(category => {
                        return (
                            <tr>
                                <th scope="row">
                                    {category.id}
                                </th>
                                <td>
                                    {category.categoryName}
                                </td>
                                <td>
                                    {''}
                                </td>
                                <td>
                                    {''}
                                </td>
                                <td>
                                    <div className="btn-group me-2">
                                        <button
                                            onClick={() => openModal(category.id, category.categoryName)}
                                            type="button"
                                            className="btn btn-sm btn-outline-secondary">
                                            Edit
                                        </button>
                                        <Button
                                            onClick={() => {
                                                setCategoryToDelete({ id: category.id, title: category.categoryName });
                                                tooggleDeleteModal();
                                            }}
                                            outline
                                            className="btn-sm">
                                            Delte
                                        </Button>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        }
        {
            topic && renderCreateCategoryForm
        }
    </>
}

export default CategoryPanel;