import axios from "axios";
import { host } from "../configs/config";

export const LoginApi = {
     getRole: () => {
          const headers = {
               'Accept': "application/json",
          };

          return axios.get(`${host.baseServerUrl}/api/getrole`, { headers });
     }
}