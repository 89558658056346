import { Configuration, LogLevel } from "@azure/msal-browser";
import { host } from "./config";

export const msalConfig: Configuration = {
    auth: {
      clientId: "9b5a5258-b93e-4fb6-b1c8-d5ef1e315162", // Client ID 
      authority: 'https://login.microsoftonline.com/fb80514c-a55d-4a25-a319-bf5fdc245116', // Tenant ID of the React.JS App Registration
      redirectUri: host.baseUiUrl,
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      loggerOptions: {
        loggerCallback: (level: any, message: string, containsPii: any) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              return;
            case LogLevel.Info:
              console.info(message);
              return;
            case LogLevel.Verbose:
              console.debug(message);
              return;
            case LogLevel.Warning:
              console.warn(message);
              return;
            }
          },
        },
      },
    };
  
// Can be found in the API Permissions of the ASP.NET Web API
export const loginApiRequest = {
  scopes: ["api://520e959a-6ee0-4061-a698-7b0278cff16c/api.scope"],
};