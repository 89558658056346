import axios from "axios";
import { host } from "../configs/config";

export const CategoryApi = {
    getCategories() {
        return axios.get(`${host.baseServerUrl}/api/categories`);
    },

    addCategory(categoryName: string, topicId: string) {
        const body = {
            categoryName: categoryName,
            topicId: topicId,
        };

        const headers = {
            'Accept': 'application/json',
        };

        const result = axios.post<any, string>(
            `${host.baseServerUrl}/api/category`,
            body,
            { headers });

        return result;
    },

    updateCategory(categoryId: string, categoryName: string) {
        const body = {
            categoryId: categoryId,
            categoryName: categoryName
        }

        const headers = {
            'Accept': 'application/json',
        };

        const result = axios.put<any, string>(
            `${host.baseServerUrl}/api/category`,
            body,
            { headers });

        return result;
    },

    deleteCategory(categoryId: string,) {
        return axios.delete(`${host.baseServerUrl}/api/categories/${categoryId}`);
    }
}