import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { RouterProvider } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import queryClient from './hooks/queryClient';
import { appRouter } from './appRouter';
import './styles/index.css';
import { Provider } from 'react-redux';
import { store } from './features/store';
import { PublicClientApplication } from '@azure/msal-browser';
import { loginApiRequest, msalConfig } from './configs/authConfig';
import axios from 'axios';
import { MsalProvider } from '@azure/msal-react';

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const msalInstance = new PublicClientApplication(msalConfig);

//axios.defaults.baseURL = "https://localhost:7060/api/";
axios.interceptors.request.use(
  async (response) => {
    const account = msalInstance.getAllAccounts()[0];

    try {
      const msalResponse = await msalInstance.acquireTokenSilent({
        ...loginApiRequest,
        account: account,
      });

      console.log('MsalRespopnse: ' + msalResponse);

      response.headers['Authorization'] = `Bearer ${msalResponse.accessToken}`;
    } catch (error) {
      console.log('Interseption Error: ' + error);
    }

    return response;
  },
  (err) => {
    return Promise.reject(err);
  }
);

/**
 * We recommend wrapping most or all of your components in the MsalProvider component.
 * It's best to render the MsalProvider as close to the root as possible.
 */
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <RouterProvider
            router={appRouter} />
        </QueryClientProvider>
      </Provider>
    </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
