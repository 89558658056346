import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { User } from '../../Models/interfaces';
import { LoginApi } from '../../api/loginApi';

export interface UserState {
    value: User;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | undefined;
}

const initialState: UserState = {
    value: {},
    status: 'idle',
    error: undefined,
}

export const getRoleAsync = createAsyncThunk(
    'user/getRole',
    async () => {
        const response = await LoginApi.getRole();

        return response.data;
    }
);

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getRoleAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getRoleAsync.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.value.role = action.payload;
            })
            .addCase(getRoleAsync.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
    }
});

export const selectUser = (state: RootState) => state.user.value;
export const selectUserStatus = (state: RootState) => state.user.status;

export default userSlice.reducer;

