import { Button, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, Input, Row } from "reactstrap";
import RichTextEditor from "../RichTextEditor";
import { FormEvent, useState } from "react";
import { UserApi } from "../../api/userApi";
import { PostApi } from "../../api/postApi";
import { TopicModel } from "../../Models/interfaces";
import TopicResponseModel from "../../ResponseModels/TopicResponseModel";
import React from "react";
import { useAppSelector } from "../../features/hooks";
import { selectTopics } from "../../features/slices/topicSlice";
import { selectCategories } from "../../features/slices/categorySlice";
import CategoryResponseModel from "../../ResponseModels/CategoryResponseModel";

const CreatePost = () => {
    const [topicDropdownOpen, setTopicDropdownOpen] = useState(false);
    const [categoryDropdownOpen, setCategoryDropdownOpen] = useState(false);
    const [topic, setTopic] = useState<TopicModel | undefined>(undefined);
    const [categories, setCategories] = useState<CategoryResponseModel[]>();
    const [category, setCategory] = useState<{ name: string, id: string }>();
    const topics = useAppSelector(selectTopics);
    const categoriesState = useAppSelector(selectCategories);
    const [postTitle, setPostTitle] = useState<string>('');
    const [postLink, setPostLink] = useState<string>('');
    const [post, setPost] = useState<string>('');

    const handleSetTopic = async (topic: TopicResponseModel) => {
        setTopic({
            id: topic.id,
            name: topic.name,
            link: topic.link
        });

        const categories = categoriesState
            .filter(category => category.topicLink === topic.link);

        setCategories(categories);
    }

    const handleAddPost = async (event: FormEvent) => {
        event.preventDefault();

        if (!category) {
            return;
        }

        PostApi.addPost(
            post,
            postTitle,
            postLink,
            '1',
            category.id);
    }

    const renderCreatePostForm = <>
        <Row style={{ marginBottom: '10px' }}>
            <Col>
                <Input
                    onChange={e => setPostTitle(e.target.value)}
                    placeholder="Post Title"
                    style={{ height: "50px" }}
                    id="title"
                    name="posttitle"
                    type="text"
                    value={postTitle} />
            </Col>
            <Col>
                <Input
                    onChange={e => setPostLink(e.target.value)}
                    placeholder="Post Link"
                    style={{ height: "50px" }}
                    id="linl"
                    name="postlink"
                    type="text"
                    value={postLink}
                />
            </Col>
        </Row>
        <RichTextEditor setState={setPost} />
    </>

    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Create Post</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="me-2">
                        <Dropdown
                            isOpen={topicDropdownOpen}
                            direction='down'
                            toggle={() => setTopicDropdownOpen((prevState) => !prevState)}>
                            <DropdownToggle
                                outline
                                caret>
                                {topic ? topic.name : 'Topics'}</DropdownToggle>
                            <DropdownMenu>
                                {topics.map(x => {
                                    return <DropdownItem
                                        onClick={e => handleSetTopic(x)}>
                                        {x.name}
                                    </DropdownItem>
                                })}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                    <div className="me-2">
                        <Dropdown
                            isOpen={categoryDropdownOpen}
                            direction='down'
                            toggle={() => setCategoryDropdownOpen((prevState) => !prevState)}>
                            <DropdownToggle
                                disabled={topic === undefined && categories === undefined}
                                outline
                                caret>{category ? category.name : 'Categories'}
                            </DropdownToggle>
                            <DropdownMenu>
                                {categories && categories.map(cat => {
                                    return <DropdownItem
                                        onClick={(event) => {
                                            setCategory({ name: cat.categoryName, id: cat.id })
                                        }}>
                                        {
                                            cat.categoryName
                                        }
                                    </DropdownItem>
                                })}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                    <div className="btn-group me-2">
                        <Button outline>Draft Post</Button>
                        <Button
                            outline
                            onClick={handleAddPost}
                            color="success">
                            Create Post
                        </Button>
                    </div>
                    <button type="button" className="btn btn-outline-secondary dropdown-toggle">
                        <span data-feather="calendar"></span>
                        This week
                    </button>
                </div>
            </div>
            {renderCreatePostForm}
        </>
    )
}

export default CreatePost;


