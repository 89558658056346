import React from "react";
import { PropsWithChildren } from "react";
import { Container } from "reactstrap";

type Props = {
    pointStyles: string[];
    loading: boolean;
};

const Loading = ({ pointStyles, loading }: Props): JSX.Element => {
    const points = pointStyles.map(pointStyle => {
        const className = `spinner-grow ${pointStyle}`;

        return <div key={pointStyle} className={className} role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    })
    
    if(loading){
        return <Container
        className="px-5 px-sm-0 px-md-0 px-lg-0 flex-shrink-0 p-5 mt-5 mt-sm-5 mt-md-4 mt-lg-0">
        <div style={{ marginTop: '40px' }}>
        <div className="d-flex justify-content-center">
        {points}
    </div>
        </div>
    </Container>
    }
    else return <></>
}

export default Loading;