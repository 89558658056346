import { FormEvent, useState } from "react";
import { TopicApi } from "../../api/topicApi";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap";
import { useQuery } from "react-query";
import { UserApi } from "../../api/userApi";
import React from "react";
import TopicResponseModel from "../../ResponseModels/TopicResponseModel";
import { Link } from "react-router-dom";

const TopicsPanel = (): JSX.Element => {
    const { isLoading, data } = useQuery<TopicResponseModel[]>(
        ['topics'],
        async () => {
            return TopicApi.getTopics()
                .then(response => response.data)
        },
        {
            enabled: true
        }
    );

    const [deleteModal, setDeleteMoadal] = useState(false);
    const [topicToDelete, setTopicToDelete] = useState<{
        id: string,
        title: string
    } | null>(null);

    const tooggleDeleteModal = () => setDeleteMoadal(!deleteModal);

    const deletePost = (id: string) => {
        TopicApi.deleteTopic(id).catch(error => console.log(error));
    }

    const handleAddTopic = async (event: FormEvent) => {
        event.preventDefault();
        const { topicname, topiclink } = document.forms[0];

      // var response = await UserApi.getCurrentUser();

        TopicApi.addTopic(topicname.value, topiclink.value, '1');
    };

    const renderCreateTopicForm = (
        <div className="form">
            <form onSubmit={handleAddTopic}>
                <div className="input-container">
                    <label>Add Topic Name </label>
                    <input type="text" name="topicname" required />
                    <label>Add Topic Link </label>
                    <input type="text" name="topiclink" required />
                </div> 
                <div className="button-container">
                    <input type="submit" />
                </div>
            </form>
        </div>
    );

    const renderTopics = (
        <Table>
            <thead>
                <tr>
                    <th>
                        Id
                    </th>
                    <th>
                        Topic Name
                    </th>
                </tr>
            </thead>
            <tbody>
                {data?.map(topic => {
                    return (
                        <tr>
                            <th scope="row">
                                {topic.id}
                            </th>
                            <td>
                                {topic.name}
                            </td>
                            <td>
                                <div className="btn-group me-2">
                                    <Button
                                        outline
                                        className="btn-sm">
                                        <Link
                                            style={{ all: 'unset' }}
                                            to={`/account/edit-topic/${topic.id}`}>
                                            Edit
                                        </Link>
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            setTopicToDelete({ id: topic.id, title: topic.name });
                                            tooggleDeleteModal();
                                        }}
                                        outline
                                        className="btn-sm">
                                        Delte
                                    </Button>
                                </div>
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>
    )

    return <>
        <Modal
            fade
            isOpen={deleteModal}
            toggle={tooggleDeleteModal}>
            <ModalHeader toggle={tooggleDeleteModal}>Delete Post</ModalHeader>
            <ModalBody>
                Are you shure you want to delete {topicToDelete?.title} post?
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={tooggleDeleteModal}>
                    Cancel
                </Button>{' '}
                <Button color="secondary" onClick={() => {
                    tooggleDeleteModal();
                    topicToDelete && deletePost(topicToDelete?.id.toString());
                }}>
                    Delete anyway
                </Button>
            </ModalFooter>
        </Modal>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h1 className="h2">Topics</h1>
            <div className="btn-toolbar mb-2 mb-md-0">
                <div className="me-2">
                    <button type="button" className="btn btn-sm btn-outline-secondary dropdown-toggle">
                        <span data-feather="calendar"></span>
                        Topics
                    </button>
                </div>
                <div className="me-2">
                    <button type="button" className="btn btn-sm btn-outline-secondary dropdown-toggle">
                        <span data-feather="calendar"></span>
                        Categories
                    </button>
                </div>
                <div className="btn-group me-2">
                    <button type="button" className="btn btn-sm btn-outline-secondary">Share</button>
                    <button type="button" className="btn btn-sm btn-outline-secondary">Export</button>
                </div>
                <button type="button" className="btn btn-sm btn-outline-secondary dropdown-toggle">
                    <span data-feather="calendar"></span>
                    This week
                </button>
            </div>
        </div>
        {renderCreateTopicForm}
        {!isLoading && renderTopics}
    </>

}

export default TopicsPanel;