// type BaseUrl = `https://${string}:${number}`

type Host = {
    baseServerUrl: string,
    baseUiUrl: string,
    tokenKey: string,
}

export const host: Host = {
   // baseServerUrl: 'https://localhost:7060',
   // baseUiUrl: 'http://localhost:3000',

     baseServerUrl: 'https://lx-blog-be.azurewebsites.net',
     baseUiUrl: 'https://xscrolls.com',
    
    tokenKey: 'accessToken',
}