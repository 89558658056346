import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CategoryResponseModel from "../../ResponseModels/CategoryResponseModel";
import { CategoryApi } from "../../api/categoryApi";
import { RootState } from "../store";

export interface CategoriesState {
    value: CategoryResponseModel[];
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
}

const initialState: CategoriesState = {
    value: [],
    status: 'idle',
}

export const getCategoriesAsync = createAsyncThunk(
    'fetchCategories',
    async () => {
        const response = await CategoryApi.getCategories();

        return response.data;
    }
);

export const categorySlice = createSlice({
    name: 'categories',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCategoriesAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getCategoriesAsync.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.value = action.payload;
            })
            .addCase(getCategoriesAsync.rejected, (state) => {
                state.status = 'failed';
            })
    }
});

export const selectCategories = (state: RootState) => state.categories.value;

export const selectCategoriesStatus = (state: RootState) => state.categories.status;

export const selectCategoriedByTopicLink = (state: RootState, topicLink) =>
    state.categories.value
        .filter(category => category.topicLink === topicLink);

export default categorySlice.reducer;