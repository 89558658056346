import { useEffect, useState } from "react";
import { searchEngineOptimizationApi } from "../api/searchEngineOptimizationApi";

function SiteMap() {
    const [xmlData, setXmlData] = useState(null);

    useEffect(() => {
        searchEngineOptimizationApi.getSiteMap()
            .then(result => {
                setXmlData(result.data);
            });
    }, []);

    return xmlData;
}

export default SiteMap;