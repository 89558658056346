import { Card, CardBody, CardText, CardTitle, Col, Container, Navbar, Row } from 'reactstrap';
import { Link, Outlet, Params, useParams } from 'react-router-dom';
import Header from './Header';
import '../styles/main.css'
import React, { useEffect } from 'react';
import { useAppDispatch } from '../features/hooks';
import { getPostsAsync, selectPosts, selectPostsStatus } from '../features/slices/postSlice';
import { useSelector } from 'react-redux';
import Loading from './loading/Loading';
import { getCategoriesAsync, selectCategoriesStatus } from '../features/slices/categorySlice';
import { Helmet } from 'react-helmet';
import Footer from './Footer';

function Main() {
  const dispatch = useAppDispatch();
  const { topicLink } = useParams<Params>();
  const postStatus = useSelector(selectPostsStatus);
  const posts = useSelector(selectPosts);
  const categoriesStatus = useSelector(selectCategoriesStatus);
  const { postLink } = useParams<Params>();

  useEffect(() => {
    window.scrollTo(0, 0)

    if (postStatus === 'idle') {
      dispatch(getPostsAsync());
    }

    if (categoriesStatus === 'idle') {
      dispatch(getCategoriesAsync());
    }
  }, [postStatus, posts, dispatch, postLink]);

  const isLoading = postStatus === 'loading';

  const result = posts?.map(post => {
    const descriptioin = post.data.substring(
      post.data.indexOf('<p>') + 3,
      post.data.indexOf('</p>'))
      .slice(0, 180) + '...';
    return (
      <Col>
        <Card
          style={{
            border: 'none', overflowY: 'hidden', 
          }}>
          <CardBody>
            <CardTitle tag="h5" >
              <Link
                className='c-link'
                to={`${post.topicLink}/${post.link}`}>{post.title}</Link>
            </CardTitle>
            <CardText
              style={{fontWeight: 'normal' }}
              className="mb-2"
              tag="h6"
              dangerouslySetInnerHTML={{ __html: descriptioin }}>
            </CardText>
          </CardBody>
        </Card>
      </Col>
    );
  });

  const view = !topicLink
    ? <>
      <Container className="pt-5
        mt-5 mt-sm-5 mt-md-5 mt-lg-4">
        <Row className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-3 g-3">
          {result}
        </Row>
      </Container>
    </>
    : <Outlet />

  return <>
    <Header collapsed={!!topicLink} />
    <Helmet>
      <meta
        name="description"
        content="Software development notes"
      />
    </Helmet>
    <Loading
      loading={isLoading}
      pointStyles={['text-danger', 'text-warning', 'text-info']} />
    {view}
    <Footer />
  </>
}

export default Main;