import axios from "axios"
import { host } from "../configs/config"

export const TopicApi = {
    getTopics() {
        return axios.get(`${host.baseServerUrl}/api/topics`);
    },

    addTopic(
        topicName: string,
        topicLink: string,
        userId: string) {
        const body = {
            topicName: topicName,
            topicLink: topicLink,
            userId: userId
        };

        const headers = {
            'Accept': 'application/json',
        };

        const result = axios.post<any, string>(`${host.baseServerUrl}/api/topic`, body, { headers });

        return result;
    },

    deleteTopic(id: string) {
        return axios.delete(`${host.baseServerUrl}/api/topics/${id}`);
    },
}