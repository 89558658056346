import { Editor } from "@tinymce/tinymce-react";
import React from 'react';
import { host } from "../configs/config";

type Props = {
  initialValue?: string;
  setState: (value: React.SetStateAction<string>) => void;
}

function RichTextEditor({ initialValue, setState }: Props): JSX.Element {
  return (
    <Editor
      onEditorChange={(a, e) => setState(a)}
      apiKey="z7mbpjbmouenimdc10ridsjejvn17ir9r03jbu65u4uxr1l1"
      initialValue={initialValue}
      init={{
        height: 700,
        menubar: true,
        plugins: [
          "codesample", "link", "autosave", "lists", "image",
        ],
        images_upload_url: `${host.baseServerUrl}/api/upload`,
        codesample_languages: [
          { text: 'HTML/XML', value: 'markup' },
          { text: 'JavaScript', value: 'javascript' },
          { text: 'CSS', value: 'css' },
          { text: 'PHP', value: 'php' },
          { text: 'Ruby', value: 'ruby' },
          { text: 'Python', value: 'python' },
          { text: 'Java', value: 'java' },
          { text: 'C', value: 'c' },
          { text: 'C#', value: 'csharp' },
          { text: 'C++', value: 'cpp' },
          { text: 'SQL', value: 'sql' }
        ],
        autosave_restore_when_empty: true,
        toolbar:
          "codesample |" +
          "link image |" +
          "undo redo | formatselect | " +
          "bold italic backcolor | alignleft aligncenter " +
          "alignright alignjustify | bullist numlist outdent indent | " +
          "removeformat | link | numlist bullist | restoredraft",
        image_title: true,
        automatic_uploads: true,
        file_picker_types: 'image',
        content_style:
          "body { font-family:Helvetica,Arial,sans-serif; font-size:16px }"
      }}
    />)
}

export default RichTextEditor;