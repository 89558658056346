import React, { useCallback, useEffect, useRef, useState } from "react";
// https://www.youtube.com/watch?v=Ny5rkEKhaxc

type changeHandler = React.ChangeEventHandler<HTMLInputElement>;

interface Props<T> {
    results?: T[];
    renderItem(item: T): JSX.Element;
    onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
    onSelect?: (item: T) => void;
    value?: string;
}

const Search = <T extends object>({
    results = [],
    renderItem,
    onChange,
    onSelect,
    value }: Props<T>) => {
    const [focusedIndex, setFocusIndex] = useState(-1);
    const resultContainer = useRef<HTMLDivElement>(null);
    const [showResults, setShowResults] = useState(false);
    const [defaultValue, setDefaultValue] = useState("");

    useEffect(() => {
        if (!resultContainer.current) {
            return;
        }

        resultContainer.current.scrollIntoView({
            block: "center",
        })
    }, [focusedIndex]);

    useEffect(() => {
        if (value) {
            setDefaultValue(value);
        }
    }, [value]);

    useEffect(() => {
        if (results.length > 0 && !showResults) {
            setShowResults(true);
        }

        if (results.length <= 0) {
            setShowResults(false)
        }
    }, [results]);

    const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
        const { key } = e;
        let nextIndexCount = 0;

        // move down
        if (key === 'ArrowDown') {
            nextIndexCount = (focusedIndex + 1) % results.length;
        }

        // move up
        if (key === 'ArrowUp') {
            nextIndexCount = (focusedIndex + results.length - 1) % results.length;
        }

        // hide search results
        if (key === 'Escape') {
            resetSearchComplete();
        }

        // select the current item
        if (key === 'Enter') {
            e.preventDefault();
            handleSelection(focusedIndex);
        }

        setFocusIndex(nextIndexCount);
    };

    const handleSelection = (selectedIndex: number) => {
        const selectedItem = results[selectedIndex];
        if (!selectedItem) {
            return resetSearchComplete();
        }

        onSelect && onSelect(selectedItem);
        resetSearchComplete();
    }

    const resetSearchComplete = useCallback(() => {
        setFocusIndex(-1);
        setShowResults(false);
    }, []);

    const handleChange: changeHandler = (e) => {
        setDefaultValue(e.target.value);
        onChange && onChange(e);
    }

    return <div
        tabIndex={1}
        onBlur={resetSearchComplete}
        onKeyDown={handleKeyDown}>
        <input
            value={defaultValue}
            onChange={handleChange}
            type="search"
            className="px-4 py-2 text-lg"
            placeholder="Search scrolls"
            style={{
                width: '100%',
                borderRadius: '15px', border: '1px solid',
                borderColor: 'rgb(168, 167, 167)',
                backgroundColor: 'rgb(250, 250, 250)'
            }} />
        {showResults
            && <div
                className="mt-0 m-2 p-2 bg-white"
                style={{
                    position: 'absolute', borderRadius: '10px', border: '1px solid',
                    borderColor: 'rgb(199, 198, 198)',
                    width: '57%', cursor: 'default',
                    maxHeight: '400px',
                    overflowY: 'auto',
                    zIndex: '100'
                }}>
                {results.map((item, index) => {
                    return (
                        <div
                            key={index}
                            onMouseDown={() => handleSelection(index)}
                            ref={index === focusedIndex ? resultContainer : null}
                            className="hover:bg-black hover:bg-opacity-10"
                            style={{
                                backgroundColor:
                                    index === focusedIndex ? 'rgba(0,0,0,0.1)' : ''
                            }}>
                            {renderItem(item)}
                        </div>
                    )
                })}
            </div>}
    </div>
}

export default Search;