import { useMsal } from "@azure/msal-react";
import React from "react"
import { Button } from "reactstrap"
import { useAppSelector } from "../../features/hooks";
import { selectUser } from "../../features/slices/userSlice";

const AccountHeader = () => {
    const { instance, inProgress } = useMsal();
    const currentUser = useAppSelector(selectUser);

    const handleLogout = () => {
        instance.logoutRedirect({ postLogoutRedirectUri: '/' });
    }

    const handleLogin = () => {
        // instance.loginRedirect(loginApiRequest).catch((e) => {
        //     console.log(e);
        // });
        instance.loginRedirect().catch((e) => {
            console.log(e);
        });
    };

    return <header className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
        <a className="navbar-brand col-md-3 col-lg-2 me-0 px-3" href="#">X Scrolls</a>
        <button className="navbar-toggler position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
        </button>
        <input className="form-control form-control-dark w-100" type="text" placeholder="Search" aria-label="Search" />
        <div className="navbar-nav">
            <div className="nav-item text-nowrap">
                {currentUser.role === 'admin'
                    ? <Button onClick={handleLogout}>Sign out</Button>
                    : <Button onClick={handleLogin}>Sign In</Button>}
            </div>
        </div>
    </header>
}

export default AccountHeader;