import { Button, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from "reactstrap";
import { useState } from "react";
import { TopicModel } from "../../Models/interfaces";
import TopicResponseModel from "../../ResponseModels/TopicResponseModel";
import { Link } from "react-router-dom";
import React from "react";
import { useAppSelector } from "../../features/hooks";
import { selectPosts } from "../../features/slices/postSlice";
import { selectTopics } from "../../features/slices/topicSlice";
import { selectCategories } from "../../features/slices/categorySlice";
import CategoryResponseModel from "../../ResponseModels/CategoryResponseModel";
import { PostApi } from "../../api/postApi";

const PostsPanel = (): JSX.Element => {
    const [topicDropdownOpen, setTopicDropdownOpen] = useState(false);
    const [categoryDropdownOpen, setCategoryDropdownOpen] = useState(false);
    const [topic, setTopic] = useState<TopicModel | undefined>(undefined);
    const [categories, setCategories] = useState<CategoryResponseModel[]>();
    const [category, setCategory] = useState<{ name: string, id: string }>();
    const posts = useAppSelector(selectPosts);
    const topics = useAppSelector(selectTopics);
    const categoriesStore = useAppSelector(selectCategories);
    const [deleteModal, setDeleteMoadal] = useState(false);
    const [postToDelete, setPostToDelete] = useState<{ id: number, title: string } | null>(null);

    const tooggleDeleteModal = () => setDeleteMoadal(!deleteModal);

    const deletePost = (id: number) => {
        PostApi.deletePost(id).catch(error => console.log(error));
    }

    const handleSetTopic = async (topic: TopicResponseModel) => {
        setTopic({
            id: topic.id,
            name: topic.name,
            link: topic.link
        });

        const categories = categoriesStore
            .filter(category => category.topicLink === topic.link)

        setCategories(categories);
    }

    return (
        <>
            <Modal
                fade
                isOpen={deleteModal}
                toggle={tooggleDeleteModal}>
                <ModalHeader toggle={tooggleDeleteModal}>Delete Post</ModalHeader>
                <ModalBody>
                    Are you shure you want to delete {postToDelete?.title} post?
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={tooggleDeleteModal}>
                        Cancel
                    </Button>{' '}
                    <Button color="secondary" onClick={() => {
                        tooggleDeleteModal();
                        postToDelete && deletePost(postToDelete?.id);
                    }}>
                        Delete anyway
                    </Button>
                </ModalFooter>
            </Modal>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Posts</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="me-2">
                        <button type="button" className="btn btn-sm btn-outline-secondary dropdown-toggle">
                            <span data-feather="calendar"></span>
                            Topics
                        </button>
                    </div>
                    <div className="me-2">
                        <button type="button" className="btn btn-sm btn-outline-secondary dropdown-toggle">
                            <span data-feather="calendar"></span>
                            Categories
                        </button>
                    </div>
                    <div className="btn-group me-2">
                        <button type="button" className="btn btn-sm btn-outline-secondary">Share</button>
                        <button type="button" className="btn btn-sm btn-outline-secondary">Export</button>
                    </div>
                    <button type="button" className="btn btn-sm btn-outline-secondary dropdown-toggle">
                        <span data-feather="calendar"></span>
                        This week
                    </button>
                </div>
            </div>
            <Row style={{ marginBottom: "60px" }}>
                <Col md={6}>
                    <Dropdown
                        isOpen={topicDropdownOpen}
                        direction='down'
                        toggle={() => setTopicDropdownOpen((prevState) => !prevState)}
                        style={{ position: 'absolute' }}>
                        <DropdownToggle color="primary" caret>{topic ? topic.name : 'Topics'}</DropdownToggle>
                        <DropdownMenu>
                            {topics?.map(x => {
                                return <DropdownItem
                                    onClick={e => handleSetTopic(x)}>
                                    {x.name}
                                </DropdownItem>
                            })}
                        </DropdownMenu>
                    </Dropdown>
                </Col>

                <Col md={6}>
                    {topic && categories &&
                        <Dropdown
                            isOpen={categoryDropdownOpen}
                            direction='down'
                            toggle={() => setCategoryDropdownOpen((prevState) => !prevState)}
                            style={{ position: 'absolute' }}>
                            <DropdownToggle
                                color='warning'
                                caret>{category ? category.name : 'Categories'}
                            </DropdownToggle>
                            <DropdownMenu>
                                {categories.map(cat => {
                                    return <DropdownItem
                                        onClick={(event) => {
                                            setCategory({ name: cat.categoryName, id: cat.id })
                                        }}>
                                        {
                                            cat.categoryName
                                        }
                                    </DropdownItem>
                                })}
                            </DropdownMenu>
                        </Dropdown>}
                </Col>
            </Row>
            <Table
                style={{ marginTop: '70px' }} >
                <thead>
                    <tr>
                        <th>
                            Id
                        </th>
                        <th>
                            Title
                        </th>
                        <th>
                            TopicId
                        </th>
                        <th>
                            Topic
                        </th>
                        <th>
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {posts?.map(post => {
                        return (
                            <tr>
                                <th scope="row">
                                    {post.postId}
                                </th>
                                <td>
                                    {post.title}
                                </td>
                                <td>
                                    {post.topicId}
                                </td>
                                <td>
                                    {post.topicName}
                                </td>
                                <td>
                                    <div className="btn-group me-2">
                                        <Button
                                            outline
                                            className="btn-sm">
                                            <Link
                                                style={{ all: 'unset' }}
                                                to={`/account/edit-post/${post.postId}`}>
                                                Edit
                                            </Link>
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                setPostToDelete({ id: post.postId, title: post.title });
                                                tooggleDeleteModal();
                                            }}
                                            outline
                                            className="btn-sm">
                                            Delte
                                        </Button>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        </>
    );
}

export default PostsPanel;