import { Button } from "reactstrap";
import PostMenu from "./PostMenu";
import { Params, useParams } from "react-router-dom";
import '../styles/sidebar.css';
import { selectCategoriedByTopicLink } from "../features/slices/categorySlice";
import { useAppSelector } from "../features/hooks";
import React from "react";
import { selectPosts } from "../features/slices/postSlice";

function SideBar() {
  const { topicLink } = useParams<Params>();
  const { postLink } = useParams<Params>();

  const categories = useAppSelector((state =>
    selectCategoriedByTopicLink(state, topicLink)));
  const posts = useAppSelector(selectPosts);

  const sidebarView = categories
    .map(category => {
      const id = `home-${category.uid}`;
      const targetId = `#${id}`;

      let collapse = false;

      category.posts.map(post => {
        if (post.link === postLink) {
          collapse = true;
        }
      })

      return (
        <li className="mb-1"
          key={category.uid}>
          <Button
            className="btn btn-toggle d-inline-flex align-items-center rounded border-0"
            data-bs-toggle="collapse"
            data-bs-target={targetId}
            // aria-expanded='true'
            aria-expanded={collapse}
            aria-controls={targetId}
          >
            {category.categoryName}
          </Button>
          <PostMenu
            key={category.id}
            posts={category.posts}
            id={id}
            collapseStyle={collapse ? "collapse show" : 'collapse'} />
        </li>
      )
    });

  return (
    <>
      {posts &&
        <div
          id="sidebarMenu"
          // position-sticky - important to use
          className="main-sidebar flex-shrink-0 p-3  container-fluid col-md-2 col-lg-2 d-xl-block collapse"
          style={{ width: 280 }}>
          <ul className="list-unstyled ps-0">
            {sidebarView}
          </ul>
        </div>}
    </>
  );
}

export default SideBar;