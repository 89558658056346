import axios from "axios";
import { host } from "../configs/config";

export const FileApi = {
    upload(formData: FormData){
        const headers = {
            'Accept': "image/*",
       };
        const result = axios.post<any, string>(
            `${host.baseServerUrl}/api/file`,
            formData);

        return result;
    },

    getFiles(){
        const result = axios.get<any, any>(
            `${host.baseServerUrl}/api/files`);

        return result;
    },
}